@import url(https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;800&display=swap);
body {
  margin: 0;
  font-family: 'Barlow',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: Barlow
}

